@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  outline: 0;
  border-width: 0;
}

html {
  font-size: 14px;
  position: relative;
  min-height: 100%;
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 2000px) {
  html {
    font-size: 18px;
  }
}

body {
  overflow-x: hidden;
  background-color: #fbfbfb;
}

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition-property: color, background-color, border-color, fill, stroke;
  transition-duration: 0.5s;
}

.dark * {
  color: white;
  transition-property: color, background-color, border-color, fill, stroke;
  transition-duration: 0.5s;
}